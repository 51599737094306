import { Component, OnInit } from '@angular/core';
import { AuthFacade } from './auth/auth.facade';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  // templateUrl: './app.component.html',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Aimtec DEEP';

  constructor(private authFacade: AuthFacade, private titleService: Title) {
    this.title = $localize`Aimtec DEEP`;
    titleService.setTitle(this.title);
  }

  ngOnInit(): void {
    this.authFacade.initAll();
    this.authFacade.loginCheck();
  }  
}
