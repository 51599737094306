import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  tap,
  map,
  switchMap,
  catchError,
  exhaustMap,
  filter,
  mergeMap
} from 'rxjs/operators';
import { of } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthActions } from './auth.action';
import { CreatePolicyConfiguration, msalConfig } from '../configs/auth.config';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { HttpEvent } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user';
import { Role } from '../models/role';
import { Group } from '../models/group';

@Injectable()
export class AuthEffects {

  // Login
  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    map(() => {
      this.authService.login();
      return AuthActions.loginProcess();
    })
  ));

  // Login check if user is login
  loginCheck$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginCheck),
    switchMap(() => this.authService.getLoginUserRoles().pipe(
      switchMap((groups: Group[]) => {
        const account = this.authService.checkAndSetActiveAccount() as AccountInfo;
        if (account) {
          return [
            AuthActions.loginCheckAuth(
              {
                user:
                {
                  name: account.name ?? account.username,
                  username: account.username,
                  roles: (account.idTokenClaims?.roles ? account.idTokenClaims.roles.map(role => ({ type: role } as Role)) : []),
                  policies: ((account.idTokenClaims && account.idTokenClaims["groups"]) ? CreatePolicyConfiguration(groups) : [])
                }
              }
            ),
          ]
        } else {
          return [AuthActions.loginCheckNoAuth()];
        }
      })
    ),
    ))
  );

  // Load user roles
  // loadLoginUserRoles$ = createEffect(() => this.actions$.pipe(
  //   ofType(AuthActions.loadUserRoles),
  //   switchMap(() => this.authService.getLoginUserRoles().pipe(
  //     map((roles: Role[]) => AuthActions.loadUserRolesSuccess({ roles: roles.filter(x => x.name) }))
  //   )
  //   )
  // ));

  // Init login
  initLoginSucess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.initLoginSuccess),
    switchMap(() => this.authService.loginSucess().pipe(
      switchMap((result: AuthenticationResult) =>
        this.authService.getLoginUserRoles().pipe(
          switchMap((groups: Group[]) => {
            return [
              AuthActions.loginSucess(
                {
                  user:
                  {
                    name: result.account?.name ?? result.account!.username,
                    username: result.account!.username,
                    roles: (result?.account?.idTokenClaims?.roles ? result.account.idTokenClaims.roles.map(role => ({ type: role } as Role)) : []),
                    policies: ((result?.account?.idTokenClaims && result?.account?.idTokenClaims["groups"]) ?
                      CreatePolicyConfiguration(groups)
                      : [])
                  }
                }
              ),
            ]
          })
        )
      ))
    ))
  );

  // Init in progress
  initLoginInProgress$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.initLoginInProgress),
    switchMap(() => this.authService.inProgress().pipe(
      map(() => AuthActions.loginProgress())
    ))
  ));

  // Init logout
  initLogoutSucess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.initLogoutSuccess),
    switchMap(() => this.authService.logoutSucess().pipe(
      map(() => AuthActions.logoutSuccess())
    ))
  ));

  // Logout
  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    tap(() => this.authService.logout())
  ), { dispatch: false });

  // Destroy
  $destroy = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.destroy),
    tap(() => this.authService.destroy())
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) { }
}