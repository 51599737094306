import { createReducer, on } from '@ngrx/store';
import { EntityState } from '@ngrx/entity';
import { MenuItem } from 'primeng/api';
import { AppActions } from './app.action';
import { HttpStatusCode } from './core/models/http-status-code';

export const APP_FEATURE_KEY = 'app';

export interface AppState {
  loading?: boolean;
  error?: ShowError;
}

export const initialState: AppState = {
  loading: false,
  error: undefined
};

export const appReducer = createReducer(
  initialState,
  on(AppActions.init,
    (_state) => { return { ..._state, loading: false } }),
  on(AppActions.throwError,
    (_state, { error }) => { return { ..._state, error: error } })
);

export interface ShowError {
  statusCode: HttpStatusCode,
  failedMessage?: string;
  message?: string;
}