import { createAction, createActionGroup, props } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { ShowError } from './app.reducer';

export const AppActions = createActionGroup({
    source: 'App',
    events: {
        'Init': props,
        'Throw Error': props<{ error: ShowError }>()
    }
})
