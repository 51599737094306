import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { MenuItem } from "primeng/api";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { AuthService } from "app/auth/services/auth.service";
import { AuthState } from "app/auth/state/auth.reducer";
import { selectAuthIsAuth } from "app/auth/state/auth.selector";
import { HttpClient, HttpStatusCode } from "@angular/common/http";
import { CoreState } from "./core/state/core.reducer";
import { CoreActions } from "./core/state/core.action";
import { ShowError } from "./core/containers/show-error/models/show-error";

@Injectable()
export class AppFacade {
    constructor(private store: Store<CoreState>) { }

    // Show error in application
    throwError(showError: ShowError) {
        this.store.dispatch(CoreActions.throwError({ error: showError }));
    }
}
