import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountEntity, AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Observable, Subject, filter, map, of, takeUntil } from 'rxjs';
import { msalConfig } from '../configs/auth.config';
import { HttpClient } from '@angular/common/http';
import { Role } from '../models/role';
import { environment } from 'environments/environment';
import { Policy } from '../models/policy';
import { Group } from '../models/group';
import { ConfigAuthOutputDto } from '@shared/api/model/config-auth-output-dto';
import { authConfig } from 'main';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient
  ) { }

  // Login
  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  // Logout
  logout() {
    this.authService.logoutRedirect({
      account: this.authService.instance.getActiveAccount(),
    });
  }

  // Login success obs
  loginSucess(): Observable<AuthenticationResult> {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
      ),
      takeUntil(this._destroying$),
      map((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        return payload;
      })
    )
  }

  // Progress obs
  inProgress(): Observable<void> {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$),
        map(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        })
      )
  }

  // Logout success obs
  logoutSucess(): Observable<void> {
    return this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS
        ),
        takeUntil(this._destroying$),
        map(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        })
      )
  }

  // If user is login
  isLogin() : boolean {
    if(this.authService.instance.getActiveAccount()) {
      return true;
    } else {
      return false;
    }
  }

  // If is set login display
  setLoginDisplay(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  // Check and set active account
  checkAndSetActiveAccount(): AccountInfo | null {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      activeAccount = accounts[0];
    }

    this.authService.instance.setActiveAccount(activeAccount);

    return activeAccount;
  }

  // Get login user groups
  getLoginUserRoles() : Observable<Group[]>  {
    return this.http.get<any>(authConfig.graphUrl + "/me/memberOf").pipe(
      map(data => (data.value as any[]).map(value => ({ id: value.id, name: value.displayName }) as Group))
    )
  }

  // Destroy
  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
