import { createAction, createActionGroup, props } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { ShowError } from '../containers/show-error/models/show-error';

export const CoreActions = createActionGroup({
    source: 'App',
    events: {
        'Init': props,
        'Throw Error': props<{ error: ShowError }>()
    }
})

export const CoreApiActions = createActionGroup({
    source: 'App API',
    events: {
        'Load Menu Items': props,
        'Load Menu Items Success': props
    }
})