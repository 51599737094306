import { DataCheckReportsRoutes, RunRoutes, SharedRoutes } from "@shared/shared.routes"
import { MenuItemDto } from "../models/menu-item-dto"
import { PolicyType } from "app/auth/models/policy-type"
import { RoleType } from "app/auth/models/role-type"

export const menuItems: MenuItemDto[] = [
    {
        id: "1",
        label: $localize`Home`,
        title: $localize`Home`,
        icon: "assets/icons/ico-home.svg",
        routerLink: SharedRoutes.homeRoute.route
    },
    {
        id: "6",
        label: $localize`Data Checks`,
        title: $localize`Data Checks`,
        icon: "assets/icons/ico-database.svg",
        items: [
            // {
            //     id: "60",
            //     label: $localize`Home`,
            //     routerLink: SharedRoutes.dataCheckRoute.route,
            // },
            {
                id: "7",
                label: $localize`Settings`,
                routerLink: SharedRoutes.dataCheckSettingsRoute.route,
            },
            {
                id: "62",
                label: $localize`Reports`,
                items: [
                    {
                        id: "63",
                        label: $localize`Bom errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Bom",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Bom]
                            },
                        ]
                    },
                    {
                        id: "64",
                        label: $localize`Bom errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Bom",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Bom]
                            },
                        ]
                    },
                    {
                        id: "65",
                        label: $localize`Calendar errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Calendar",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Calendar]
                            },
                        ]
                    },
                    {
                        id: "66",
                        label: $localize`Calendar errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Calendar",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Calendar]
                            },
                        ]
                    },
                    {
                        id: "67",
                        label: $localize`Customer errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Customer",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Customer]
                            },
                        ]
                    },
                    {
                        id: "68",
                        label: $localize`Customer errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Customer",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Customer]
                            },
                        ]
                    },
                    {
                        id: "71",
                        label: $localize`Inventory errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Inventory",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Order_Inventory]
                            },
                        ]
                    },
                    {
                        id: "72",
                        label: $localize`Inventory errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Inventory",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Order_Inventory]
                            },
                        ]
                    },
                    {
                        id: "73",
                        label: $localize`Item errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Item",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Item]
                            },
                        ]
                    },
                    {
                        id: "74",
                        label: $localize`Item errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Item",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Item]
                            },
                        ]
                    },
                    {
                        id: "75",
                        label: $localize`Manufacturing errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Manufacturing",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Order_Manufacturing]
                            },
                        ]
                    },
                    {
                        id: "76",
                        label: $localize`Manufacturing errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Manufacturing",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Order_Manufacturing]
                            },
                        ]
                    },
                    {
                        id: "77",
                        label: $localize`Operation errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Operation",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Operation]
                            },
                        ]
                    },
                    {
                        id: "78",
                        label: $localize`Operation errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Operation",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Operation]
                            },
                        ]
                    },
                    {
                        id: "79",
                        label: $localize`Output errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Output",                 
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Output]
                            },
                        ]
                    },
                    {
                        id: "80",
                        label: $localize`Output errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Output",                      
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Output]
                            },
                        ]
                    },
                    {
                        id: "81",
                        label: $localize`Purchase errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Purchase",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Purchase]
                            },
                        ]
                    },
                    {
                        id: "82",
                        label: $localize`Purchase errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Purchase",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Purchase]
                            },
                        ]
                    },
                    {
                        id: "83",
                        label: $localize`Resource errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Resource",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Resource]
                            },
                        ]
                    },
                    {
                        id: "84",
                        label: $localize`Resource errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Resource",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Resource]
                            },
                        ]
                    },
                    {
                        id: "85",
                        label: $localize`Routing errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Routing",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Routing]
                            },
                        ]
                    },
                    {
                        id: "86",
                        label: $localize`Routing errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Routing",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Routing]
                            },
                        ]
                    },
                    {
                        id: "87",
                        label: $localize`Sale errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Sales",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Order_Sales]
                            },
                        ]
                    },
                    {
                        id: "88",
                        label: $localize`Sale errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Sales",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Order_Sales]
                            },
                        ]
                    },
                    {
                        id: "89",
                        label: $localize`Shift errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Shift",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Shift]
                            },
                        ]
                    },
                    {
                        id: "90",
                        label: $localize`Shift errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Shift",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Shift]
                            },
                        ]
                    },
                    {
                        id: "91",
                        label: $localize`Subresource errors`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Overview_File_1_0_Subresource",      
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Subresource]
                            },
                        ]
                    },
                    {
                        id: "92",
                        label: $localize`Subresource errors cumulation`,
                        routerLink: DataCheckReportsRoutes.baseRoute.paramRoute._ + "Data_Error_Report_DEEPAPP_Summary_File_1_0_Subresource",
                        authAccess: [
                            {
                                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                                rolesAccess: []
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Administrator_Full]
                            },
                            {
                                policiesAccess: [],
                                rolesAccess: [RoleType.Checks_Subresource]
                            },
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: "13",
        label: $localize`Integration`,
        title: $localize`Integration`,
        icon: "assets/icons/ico-dataflow.svg",
        items: [
            {
                id: "17",
                label: $localize`Import Data`,
                items: [
                    {
                        id: "18",
                        label: $localize`Boms`,
                        routerLink: SharedRoutes.integrationBomRoute.route,
                    },
                    {
                        id: "19",
                        label: $localize`Calendars`,
                        routerLink: SharedRoutes.integrationCalendarRoute.route,
                    },
                    {
                        id: "20",
                        label: $localize`Customers`,
                        routerLink: SharedRoutes.integrationCustomerRoute.route,
                    },
                    {
                        id: "22",
                        label: $localize`Inventories`,
                        routerLink: SharedRoutes.integrationInventoryRoute.route,
                    },
                    {
                        id: "23",
                        label: $localize`Items`,
                        routerLink: SharedRoutes.integrationItemRoute.route,
                    },
                    {
                        id: "24",
                        label: $localize`Manufacturings`,
                        routerLink: SharedRoutes.integrationManufacturingRoute.route,
                    },
                    {
                        id: "25",
                        label: $localize`Operations`,
                        routerLink: SharedRoutes.integrationOperationRoute.route,
                    },
                    {
                        id: "52",
                        label: $localize`Outputs`,
                        routerLink: SharedRoutes.integrationOutputRoute.route,
                    },
                    {
                        id: "53",
                        label: $localize`Purchases`,
                        routerLink: SharedRoutes.integrationPurchaseRoute.route,
                    },
                    {
                        id: "54",
                        label: $localize`Resources`,
                        routerLink: SharedRoutes.integrationResourceRoute.route,
                    },
                    {
                        id: "55",
                        label: $localize`Routings`,
                        routerLink: SharedRoutes.integrationRoutingRoute.route,
                    },
                    {
                        id: "56",
                        label: $localize`Sales`,
                        routerLink: SharedRoutes.integrationSaleRoute.route,
                    },
                    {
                        id: "57",
                        label: $localize`Shifts`,
                        routerLink: SharedRoutes.integrationShiftRoute.route,
                    },
                    {
                        id: "58",
                        label: $localize`Subresources`,
                        routerLink: SharedRoutes.integrationSubresourceRoute.route
                    }
                ]
            },
            {
                id: "93",
                label: $localize`Export Data`,
                items: [
                    {
                        id: "94",
                        label: $localize`Operation Export`,
                        routerLink: SharedRoutes.integrationOperationExportRoute.route
                    },
                    {
                        id: "95",
                        label: $localize`Order Export`,
                        routerLink: SharedRoutes.integrationOrderExportRoute.route
                    }
                ]
            },
            {
                id: "61",
                label: $localize`Runs`,
                items: [
                    {
                        id: "62",
                        label: $localize`Last run`,
                        routerLink: SharedRoutes.integrationRoute.route! + RunRoutes.lastRunRoute.route
                    },
                    {
                        id: "15",
                        label: $localize`Manual run import`,
                        routerLink: SharedRoutes.integrationManualRunRoute.route! + RunRoutes.manualRunImportRoute.route,
                    },
                    {
                        id: "16",
                        label: $localize`Manual run export`,
                        routerLink: SharedRoutes.integrationManualRunRoute.route! + RunRoutes.manualRunExportRoute.route,
                    }
                ]
            }
        ]
    },
    // {
    //     id: "26",
    //     label: $localize`Planning`,
    //     title: $localize`Planning`,
    //     icon: "assets/icons/ico-chart-board.svg",
    //     items: [

    //     ]
    // },
    // {
    //     id: "39",
    //     label: $localize`PMT`,
    //     title: $localize`PMT`,
    //     icon: "assets/icons/ico-chart.svg",
    //     items: [

    //     ],
    //     authAccess: [
    //         {
    //             policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
    //             rolesAccess: []
    //         },
    //         {
    //             policiesAccess: [RoleType.Administrator_Full],
    //             rolesAccess: []
    //         },
    //         {
    //             policiesAccess: [PolicyType.ASP_Reader],
    //             rolesAccess: []
    //         },
    //         {
    //             policiesAccess: [],
    //             rolesAccess: [RoleType.PMT]
    //         }
    //     ]
    // }
]

export const userItems: MenuItemDto[] = [
    {
        id: "2",
        label: $localize`API Access`,
        routerLink: SharedRoutes.apiAccessRoute.route,
        authAccess: [
            {
                policiesAccess: [PolicyType.ASP_DEEPAPP_Full_Administrator],
                rolesAccess: []
            },
            {
                policiesAccess: [],
                rolesAccess: [RoleType.Administrator_Full]
            },
            {
                policiesAccess: [],
                rolesAccess: [RoleType.ApiAccess_Default]
            },
        ]
    },
    {
        id: "3",
        label: 'Log Out'
    }
]
