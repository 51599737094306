import { createAction, createActionGroup, props } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { User } from '../models/user';
import { Role } from '../models/role';

export const AuthActions = createActionGroup({
    source: 'Auth',
    events: {
        'Init Login Success': props,
        'Login': props,
        'Login Process': props,
        'Login Fail': props<{ error: any }>(),
        'Login Sucess': props<{ user: User }>(),
        'Login Check': props,
        'Login Check Auth': props<{ user: User }>(),
        'Login Check No Auth': props,
        'Init Login In Progress': props,
        'Login Progress': props,

        'Init Logout Success': props,
        'Logout Success': props,

        'Logout': props,

        'Destroy': props
    }
})

export const AuthApiActions = createActionGroup({
    source: 'Auth API',
    events: {
    }
})