import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { MenuItem } from "primeng/api";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { AuthState } from "./state/auth.reducer";
import { AuthActions } from "./state/auth.action";
import { selectAuthIsAuth, selectAuthUser } from "./state/auth.selector";
import { AuthService } from "./services/auth.service";

@Injectable()
export class AuthFacade {
    user$ = this.store.select(selectAuthUser);
    isAuth$ = this.store.select(selectAuthIsAuth);

    constructor(private store: Store<AuthState>, private authService: AuthService) { }

    // Init 
    initAll() {
        this.store.dispatch(AuthActions.initLoginSuccess());      
        this.store.dispatch(AuthActions.initLoginInProgress());      
        this.store.dispatch(AuthActions.initLogoutSuccess());      
    }

    // Login
    login() {
        this.store.dispatch(AuthActions.login());
    }

    loginCheck() {
        this.store.dispatch(AuthActions.loginCheck());
    }

    logout() {
        this.store.dispatch(AuthActions.logout());
    }

    destroy() {
        this.store.dispatch(AuthActions.destroy());
    }

    isLogin() {
        return this.authService.isLogin();
    }
}