import { HttpStatusCode } from "./http-status-code";
import { ValidationModel } from "./validation-model";

export class ApiResult<TData> {
    data?: TData;
    failedMessage?: string;
    statusCode?: HttpStatusCode;
    isRefreshTokenInvalid?: boolean;
    isSuccess?: boolean;
    isTokenInvalid?: boolean;
    message?: string;
    successMessage?: string;
    validations?: Array<ValidationModel>;

    // Zda jde o api result
    static isApiResult(obj: any): boolean {
        return obj &&
               obj.hasOwnProperty("data") &&
               obj.hasOwnProperty("failedMessage") &&
               obj.hasOwnProperty("statusCode") &&
               obj.hasOwnProperty("isRefreshTokenInvalid") &&
               obj.hasOwnProperty("isSuccess") &&
               obj.hasOwnProperty("isTokenInvalid") &&
               obj.hasOwnProperty("message") &&
               obj.hasOwnProperty("successMessage") &&
               obj.hasOwnProperty("validations");
    }
}
