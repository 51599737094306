import { createReducer, on } from '@ngrx/store';
import { EntityState } from '@ngrx/entity';
import { MenuItem } from 'primeng/api';
import { AuthActions } from './auth.action';
import { User } from '../models/user';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  loading?: boolean;
  user?: User;
  error?: string;
  isAuth: boolean;
}

export const initialState: AuthState = {
  loading: false,
  user: undefined,
  error: undefined,
  isAuth: false
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.login,
    (_state) => { return { ..._state, user: undefined, loading: true } }),  
  on(AuthActions.loginProcess,
      (_state) => { return { ..._state, loading: true, user: undefined } }),
  on(AuthActions.loginSucess,
    (_state, {user}) => { return { ..._state, loading: false, error: undefined, user: user, isAuth: true } }),
  // on(AuthActions.loadUserRoles,
  //   (_state) => { return { ..._state, loading: true } }),  
  // on(AuthActions.loadUserRolesSuccess,
  //   (_state, {roles}) => { return { ..._state, loading: false, user: { ..._state.user!, roles } } }),  
  on(AuthActions.loginCheck,
    (_state) => { return { ..._state, loading: true, user: undefined } }),
  on(AuthActions.loginCheckAuth,
    (_state, {user}) => { return { ..._state, loading: false, error: undefined, user: user, isAuth: true } }),
  on(AuthActions.loginCheckNoAuth,
    (_state) => { return { ..._state, loading: false, user: undefined } }),
  on(AuthActions.loginFail,
    (_state, {error}) => { return { ..._state, loading: false, error: error, user: undefined } }),
  on(AuthActions.logout,
    (_state) => { return { ..._state, ...initialState } }),
);