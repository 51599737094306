export enum RoleType {
    Checks_Calendar = "Checks_Calendar",
    Checks_Customer = "Checks_Customer",
    Checks_Bom = "Checks_Bom",
    Checks_Order_Inventory = "Checks_Order_Inventory",
    Checks_Item = "Checks_Item",
    Checks_Order_Manufacturing = "Checks_Order_Manufacturing",
    Checks_Subresource = "Checks_Subresource",
    Checks_Operation_Export = "Checks_Operation_Export",
    Checks_Operation = "Checks_Operation",
    Checks_Output = "Checks_Output",
    Checks_Purchase = "Checks_Purchase",
    Checks_Resource = "Checks_Resource",
    Checks_Routing = "Checks_Routing",
    Checks_Order_Sales = "Checks_Order_Sales",
    Checks_Shift = "Checks_Shift",
    Checks_Order_Export = "Checks_Order_Export",
    Archivation = "Archivation",
    Base_Report = "Base_Report",
    Notification = "Notification",
    Transformation = "Transformation",
    Plan_Vs_Reality = "Plan_Vs_Reality",
    PMT = "PMT",
    ApiAccess_Default = "ApiAccess_Default",
    Administrator_Full = "Administrator_Full"
}