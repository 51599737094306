<div class="col-12 md:col-10 md:col-offset-1">
    <p-card styleClass="m-0 align-items-center">
        <div class="flex flex-column align-items-center">
            <img class="h-6rem" src="../../../../assets/icons/ico-warning.svg" />
            <div class="text-8xl font-bold" i18n>Oops!</div>
            <h2 class="mb-0" *ngIf="this.error?.statusCode">{{ this.error!.statusCode }}</h2>
            <h3 i18n>Something went wrong.</h3>
            <div class="error" *ngIf="!this.isProduction && this.error?.failedMessage" >{{ this.error!.failedMessage }}</div>
        </div>
    </p-card>
</div>