import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreRoutingModule, coresRoutes } from './core/core-routing.module';
import { CoreModule } from './core/core.module';
import { AppErrorHandlerInterceptor } from './app-error-handler.interceptor';
import * as fromApp from './app.reducer';
import { RouterModule } from '@angular/router';
import { AppFacade } from './app.facade';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    AppRoutingModule,
    EffectsModule.forRoot(),
    StoreModule.forRoot({}, {
      metaReducers: [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    RouterModule.forChild(coresRoutes),
    StoreModule.forFeature(fromApp.APP_FEATURE_KEY, fromApp.appReducer),
  ], 
  providers: [
    AppFacade,
    [ { provide: HTTP_INTERCEPTORS, useClass: AppErrorHandlerInterceptor, multi: true } ]
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
