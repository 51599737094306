<app-auth>
  <app-loader [loading]="loading">
    <p-confirmDialog></p-confirmDialog>
    <div class="grid h-full flex-column">
      <div class="flex flex-column min-h-screen">
        <header class="flex flex-column">
          <app-navbar [mobileItems]="menuItems" [userItems]="userItems" class="navbar-container"></app-navbar>
        </header>
        <div class="flex flex-auto flex-row">
          <aside class="hidden md:flex sidepanel-container">
            <app-sidepanel [actualRoute]="actualRoute" [disableRoutes]="['/', '/home']"
              [items]="menuItems"></app-sidepanel>
          </aside>
          <content class="flex-auto grid-nogutter py-4 content-container">
            <router-outlet></router-outlet>
          </content>
        </div>
        <footer class="flex flex-column z-3">
          <app-footer class="footer-container"></app-footer>
        </footer>
      </div>
    </div>
  </app-loader>
</app-auth>