import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { CoreComponent } from './core/containers/core/core.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    children: [{
      path: '', 
      loadChildren: () => import('./core/core.module').then(m => m.CoreModule)
    }]
  },
  {
    path: '**',
    redirectTo: ""
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups

    onSameUrlNavigation: 'reload',
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? 'enabledNonBlocking'
        : 'disabled', // Set to enabledBlocking to use Angular Universal
  }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
