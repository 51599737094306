import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { CORE_FEATURE_KEY, CoreState } from './core.reducer';
import { APP_FEATURE_KEY, AppState } from 'app/app.reducer';
import { AUTH_FEATURE_KEY, AuthState } from 'app/auth/state/auth.reducer';

export const selectCoreState = createFeatureSelector<CoreState>(CORE_FEATURE_KEY);
export const selectAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);
export const selectAuthState = createFeatureSelector<AuthState> (AUTH_FEATURE_KEY);

// export const selectAppMenuItems = createSelector(selectAppState, (state) => state.menuItems);
export const selectAppError = createSelector(selectAppState, (state) => state.error);

export const selectUser = createSelector(selectAuthState, (state) => state.user);