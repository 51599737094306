import { BaseRoute } from "../shared/models/baseRoute";

export class SharedRoutes {
    static readonly homeRoute: BaseRoute = { name: "home", route: "/home", titleName: $localize `Home` }
    static readonly authRoute: BaseRoute = { name: "auth", route: "/auth", titleName: $localize `Auth` }

    static readonly errorWithCodeRoute: BaseRoute = { name: "error/:code", route: "/error/:code", titleName: $localize `Error` }
    static readonly errorRoute: BaseRoute = { name: "error", route: "/error", titleName: $localize `Error` }

    static readonly apiAccessRoute: BaseRoute = { name: "api-access", route: "/api-access", titleName: $localize `API Access` }

    static readonly integrationConfigurationRoute: BaseRoute = {name: "integration/configuration", route: "/integration/configuration", titleName: $localize `Configuration`}
    static readonly integrationRoute: BaseRoute = { name: "integration", route: "/integration", titleName: $localize `Integration` }
    static readonly integrationBomRoute: BaseRoute = { name: "integration/boms", route: "/integration/boms", titleName: $localize `BOMs` }
    static readonly integrationCalendarRoute: BaseRoute = { name: "integration/calendars", route: "/integration/calendars", titleName: $localize `Calendars` }
    static readonly integrationCustomerRoute: BaseRoute = { name: "integration/customers", route: "/integration/customers", titleName: $localize `Customers` }
    static readonly integrationInventoryRoute: BaseRoute = { name: "integration/inventories", route: "/integration/inventories", titleName: $localize `Inventories` }
    static readonly integrationItemRoute: BaseRoute = { name: "integration/items", route: "/integration/items", titleName: $localize `Items` }
    static readonly integrationManufacturingRoute: BaseRoute = { name: "integration/manufacturings", route: "/integration/manufacturings", titleName: $localize `Manufacturings` }
    static readonly integrationOperationRoute: BaseRoute = { name: "integration/operations", route: "/integration/operations", titleName: $localize `Operations` }
    static readonly integrationOutputRoute: BaseRoute = { name: "integration/outputs", route: "/integration/outputs", titleName: $localize `Outputs` }
    static readonly integrationPurchaseRoute: BaseRoute = { name: "integration/purchases", route: "/integration/purchases", titleName: $localize `Purchases` }
    static readonly integrationResourceRoute: BaseRoute = { name: "integration/resources", route: "/integration/resources", titleName: $localize `Resources` }
    static readonly integrationRoutingRoute: BaseRoute = { name: "integration/routings", route: "/integration/routings", titleName: $localize `Routings` }
    static readonly integrationSaleRoute: BaseRoute = { name: "integration/sales", route: "/integration/sales", titleName: $localize `Sales` }
    static readonly integrationShiftRoute: BaseRoute = { name: "integration/shifts", route: "/integration/shifts", titleName: $localize `Shifts` }
    static readonly integrationSubresourceRoute: BaseRoute = { name: "integration/subresources", route: "/integration/subresources", titleName: $localize `Subresources` }
    static readonly integrationManualRunRoute: BaseRoute = {name: "integration/manual-run", route: "/integration/manual-run", titleName: $localize `Manual Run`}
    static readonly integrationOperationExportRoute: BaseRoute = { name: "integration/operation-export", route: "/integration/operation-export", titleName: $localize `Operation Export` }
    static readonly integrationOrderExportRoute: BaseRoute = { name: "integration/order-export", route: "/integration/order-export", titleName: $localize `Order Export` }
    
    static readonly dataCheckSettingsRoute: BaseRoute = { name: "data-checks/settings", route: "/data-checks/settings", titleName: $localize `Data Check Settings` }
    static readonly dataCheckReportRoute: BaseRoute = { name: "data-checks/reports", route: "/data-checks/reports", titleName: $localize `Data Check Reports` }
    static readonly dataCheckRoute: BaseRoute = {name: "data-checks/home", route: "/data-checks/home", titleName: $localize `Data Checks`}

    static readonly Route: BaseRoute = { name: "", route: "/" }
}

export class ApiAccessRoutes {
    static readonly newRoute: BaseRoute = { name: "api-access/generate", route: "/api-access/generate", titleName: $localize `Generate Secret`}
}

export class DataCheckSettingsRoutes {
    static readonly editRoute: BaseRoute = { routeName: {_:"data-checks/settings/", idParam: ":id", edit: "/edit"}, paramRoute: {_: "/data-checks/settings/", idParam:":id", edit: "/edit"}, titleName: $localize `Check Edit` }
}

export class DataCheckReportsRoutes {
    static readonly baseRoute: BaseRoute = { routeName: {_:"data-checks/reports/", idParam: ":name"}, paramRoute: {_: "/data-checks/reports/", idParam:":name" } }
}

export class RunRoutes {
    static readonly lastRunRoute: BaseRoute = { name: "runs/last-run", route: "/runs/last-run", titleName: $localize `Last Run` }
    static readonly manualRunImportRoute: BaseRoute = { name: "runs/manual-run-import", route: "/runs/manual-run-import", titleName: $localize `Manual Run Import` }
    static readonly manualRunExportRoute: BaseRoute = { name: "runs/manual-run-export", route: "/runs/manual-run-export", titleName: $localize `Manual Run Export` }
}