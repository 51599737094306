import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Observable, catchError, filter, finalize, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AppFacade } from './app.facade';
import { ApiResult } from './core/models/api-result';

// Interceptor na odchytávání errorů při volání API
@Injectable()
export class AppErrorHandlerInterceptor implements HttpInterceptor {

  // constructor() {}
  constructor(private router: Router, private appFacade: AppFacade) {}

  // Interceptor which catch error on api  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        if(err instanceof HttpErrorResponse) {
          if(ApiResult.isApiResult(err.error)) {
            if(err.status == HttpStatusCode.UnprocessableEntity) { //Exclude validations      
              return throwError(() => err);
            }
            this.appFacade.throwError({
              statusCode: err.error.statusCode,
              message: err.error.message,
              failedMessage: err.error.failedMessage
            });
          } else {
            this.appFacade.throwError({
              statusCode: err.status,
              failedMessage: err.message
            });
          }
          this.router.navigate(['error']);
        } 
        return throwError(() => err);
      })
    );
  }
}
  