import { FilterMetadata, SortMeta } from "primeng/api";
import buildQuery from 'odata-query';
import { Injectable } from "@angular/core";
import { AuthAccessCheckType } from "../models/auth-access-check-type";
import { AuthAccessLogicalType } from "../models/auth-access-logical-type";
import { AuthAccess } from "../models/auth-access";
import { RoleType } from "../models/role-type";
import { PolicyType } from "../models/policy-type";
import { Policy } from "../models/policy";
import { Role } from "../models/role";

@Injectable({
    providedIn: 'root'
})
export class AuthAccessUtils {

    // Evaluation policies with roles in one
    evaluationOfAuths(policies: Policy[], roles: Role[], authAccess: AuthAccess[], authAccessLogicalType: AuthAccessLogicalType = AuthAccessLogicalType.Or): boolean {
        if(!authAccess || authAccess.length == 0) {
            return true;
        }
        
        if(authAccessLogicalType === AuthAccessLogicalType.And) {
            for(let auth of authAccess) { 
                if(!this.evaluationOfAuth(policies, roles, auth)) {
                    return false;
                }
            }
            return true;
        } else if(authAccessLogicalType === AuthAccessLogicalType.Or) {
            for(let auth of authAccess) { 
                if(this.evaluationOfAuth(policies, roles, auth)) {
                    return true;
                }
            }
            return false;
        } 

        return false;
    }

    // Evaluation policies with roles in one
    evaluationOfAuth(policies: Policy[], roles: Role[], authAccess: AuthAccess): boolean {
        if(!authAccess.policiesCheckType) authAccess.policiesCheckType = AuthAccessCheckType.All;
        if(!authAccess.rolesCheckType) authAccess.rolesCheckType = AuthAccessCheckType.All;
        if(!authAccess.authAccessLogicalType) authAccess.authAccessLogicalType = AuthAccessLogicalType.And;

        if (authAccess.authAccessLogicalType == AuthAccessLogicalType.And) {
            return (authAccess.policiesAccess.length == 0 || this.evaluationOfRight(authAccess.policiesAccess, policies.map(x => x.type), authAccess.policiesCheckType))
                && (authAccess.rolesAccess.length == 0 || this.evaluationOfRight(authAccess.rolesAccess, roles.map(x => x.type), authAccess.rolesCheckType));
        }
        else {
            return (authAccess.policiesAccess.length == 0 || this.evaluationOfRight(authAccess.policiesAccess, policies.map(x => x.type), authAccess.policiesCheckType))
                || (authAccess.rolesAccess.length == 0 || this.evaluationOfRight(authAccess.rolesAccess, roles.map(x => x.type), authAccess.rolesCheckType));
        }
    }

    // Evaluation if have access by roles
    private evaluationOfRight(rightsAccess: string[], rights: string[], checkType?: AuthAccessCheckType): boolean {
        if ((checkType == AuthAccessCheckType.All || !checkType) && !rightsAccess.every(right => rights.includes(right))) {
            return false;
        } else if (checkType == AuthAccessCheckType.Any && !rightsAccess.some(right => rights.includes(right))) {
            return false;
        } else if (checkType == AuthAccessCheckType.Nothing && rightsAccess.every(right => rights.includes(right))) {
            return false;
        }

        return true;
    }
}