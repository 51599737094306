import { Component, OnInit, OnDestroy } from '@angular/core';
import { CoreFacade } from '../../core.facade';
import { ShowError } from '../../containers/show-error/models/show-error';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { HttpStatusCode } from 'app/core/models/http-status-code';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-error',
  templateUrl: './show-error.component.html',
  styleUrls: ['./show-error.component.scss']
})
export class ShowErrorComponent implements OnInit, OnDestroy {

  code?: number;
  error?: ShowError;
  isProduction: boolean = environment.production;

  private subscriptions: Subscription[] = [];

  constructor(private coreFacade: CoreFacade, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    let code = Number(this.route.snapshot.paramMap.get("code"));
    if (!code) {
      this.subscriptions.push(this.coreFacade.error$.subscribe(error => {
        this.error = error
      }));
    } else {
      this.error = { statusCode: code }
      if(code == HttpStatusCode.$404) 
        this.error.failedMessage = $localize`The page not found`;
      else if(code == HttpStatusCode.$401)
      this.error.failedMessage = $localize`You do not have rights to view this content`;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
