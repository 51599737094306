import { createReducer, on } from '@ngrx/store';
import { EntityState } from '@ngrx/entity';
import { MenuItem } from 'primeng/api';
import { ShowError } from '../containers/show-error/models/show-error';
import { CoreActions, CoreApiActions } from './core.action';

export const CORE_FEATURE_KEY = 'core';

export interface CoreState {
  loading?: boolean;
  actualRoute: string
  error?: ShowError;
}

export const initialState: CoreState = {
  actualRoute: '/home',
  loading: false,
  error: undefined,
};

export const coreReducer = createReducer(
  initialState,
  on(CoreActions.init,
    (_state) => { return { ..._state, loading: false } }),
  on(CoreApiActions.loadMenuItems,
    (_state) => { return { ..._state, loading: true } }),
  on(CoreApiActions.loadMenuItemsSuccess,
    (_state) => { return { ..._state, loading: false } }),
  on(CoreActions.throwError,
    (_state, { error }) => { return { ..._state, error: error } })
);