/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from 'environments/environment';
import { InjectionToken, enableProdMode } from '@angular/core';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { ApiResult } from 'app/core/models/api-result';
import { ConfigAuthOutputDto } from '@shared/api/model/config-auth-output-dto';
import { lastValueFrom, map, share } from 'rxjs';

const apiUrl = environment.dataIntegratorApiUrl + "/api/data-integrator/v1";
const http = new HttpClient (new HttpXhrBackend({build: () => new XMLHttpRequest()}));

export let authConfig: ConfigAuthOutputDto;

if (environment.production) {
  enableProdMode();
}

lastValueFrom(http.get<ApiResult<ConfigAuthOutputDto>>(`${apiUrl}/auth/config`))
.then(res => {
  authConfig = res.data as ConfigAuthOutputDto;

  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
